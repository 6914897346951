import "./styles.css";
import axios from "axios";
import { useEffect, useState, Alert } from "react";

import generalindex from "./jsons/index_general.json";
import index_items from "./jsons/index_items.json";
import index_mobs from "./jsons/index_mobs.json";

import droppedBy from "./jsons/default/dropped_by_default.json";
import items_default from "./jsons/default/item_db_default.json";
import item_descriptions_default from "./jsons/default/item_description_default.json";
import mobs_default from "./jsons/default/mob_db_default.json";
import spawn_default from "./jsons/default/mob_spawn_default.json";


import mvps from "./jsons/mob_mvps.json";

import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
import 'bootstrap/dist/css/bootstrap.min.css';

import EpisodeDescriptionsBlock from './components/episode_descriptions.js';

const DROP_SLOTS_ARRAY = [1,2,3,4,5,6,7,8,9];


const DropEntry = ({
  dropID,
  dropRateMultiplier,
  baseDropRate,
  makeDropClickable,
  itemIndex,   // now coming from index_items
  currentEpisode,
  currentLanguage
}) => {
  const getFormattedDropRate = () => {
    const minDropRate = dropRateMultiplier || 1;
    const formattedDropRate = (baseDropRate * minDropRate) / 100;
    return formattedDropRate > 100 ? 100 : formattedDropRate;
  };

  return (
    <li className={`title-${currentEpisode} link`} onClick={() => makeDropClickable(dropID)}>
      <img
        src={`https://vitcunha.s3.sa-east-1.amazonaws.com/ragnadb/icons/${dropID}.png`}
        alt=""
      />
      { itemIndex[dropID] ? itemIndex[dropID][currentLanguage] : dropID }: {getFormattedDropRate()}%
    </li>
  );
};




{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}
{/* FUNCTION APP() */}
{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}

export default function App() {

  // Search Functions
  const [filteredMobs, setfilteredMobs] = useState([]);
  const [filteredItems, setfilteredItems] = useState([]);
  const [inputBusca, setinputBusca] = useState("");
  const [selectedMob, setselectedMob] = useState(null);
  const [selectedItem, setselectedItem] = useState(null);

  // Global Parameters
  const [baseExpMultiplier, setbaseExpMultiplier] = useState("1");
  const [jobExpMultiplier, setjobExpMultiplier] = useState("1");
  const [dropRateMultiplier, setdropRateMultiplier] = useState("1");

  // Language Handling
  const [currentLanguage, setcurrentLanguage] = useState("en-us");

  // Set Current Language Function
  const handleChangeLanguage = async (language) => {
    setcurrentLanguage(language);
  };


  //  Episode Functions - Create variables, as well as create the functions that change their value.
  const [currentEpisode, setcurrentEpisode] = useState("default");
  const [currentEpisodeItemDB, setcurrentEpisodeItemDB] = useState(items_default);
  const [currentEpisodeMobDB, setcurrentEpisodeMobDB] = useState(mobs_default);
  const [currentEpisodeMobSpawn, setcurrentEpisodeMobSpawn] = useState(spawn_default);
  const [currentEpisodeDroppedBy, setcurrentEpisodeDroppedBy] = useState(droppedBy);
  const [currentEpisodeItemDescription, setcurrentEpisodeItemDescription] = useState(item_descriptions_default);
  const [selectedEpisodeDetails, setselectedEpisodeDetails] = useState(null);

  
  // Episode Handling - Actually change stuff when a different episode is chosen
  const handleEpisodeChange = async (episode) => {
    try {
      const newDroppedBy = await import(`./jsons/${episode}/dropped_by_${episode}.json`);
      const newItemDB = await import(`./jsons/${episode}/item_db_${episode}.json`);
      const newItemDescription = await import(`./jsons/${episode}/item_description_${episode}.json`);
      const newMobDB = await import(`./jsons/${episode}/mob_db_${episode}.json`);
      const newMobSpawn = await import(`./jsons/${episode}/mob_spawn_${episode}.json`);
      setcurrentEpisodeDroppedBy(newDroppedBy.default);
      setcurrentEpisodeMobSpawn(newMobSpawn.default);
      setcurrentEpisodeItemDB(newItemDB.default);
      setcurrentEpisodeMobDB(newMobDB.default);
      setcurrentEpisodeItemDescription(newItemDescription.default);
      setcurrentEpisode(episode);
      {selectedEpisodeDetails !== null && (setselectedEpisodeDetails(episode))};
    } catch (error)
      {
      alert('Episode not supported yet');}
  
  };





  //  Distribution Functions
  const [currentDistribution, setcurrentDistribution] = useState("iRO");
  const [currentDistributionItemDB, setcurrentDistributionItemDB] = useState("");
  const [currentDistributionMobDB, setcurrentDistributionMobDB] = useState("");

  const handleDistributionChange = async (distribution) => {
    try {

      throw new Error("Simulated error: Distribution change failed.");
      
      const newItemDB = await import(`./jsons/item_db_${distribution}.json`);
      const newMobDB = await import(`./jsons/mob_db_${distribution}.json`);
      setcurrentDistributionItemDB([newItemDB]);
      setcurrentDistributionMobDB([newMobDB]);
      setcurrentDistribution(distribution);
    } catch (error)
    {
      alert('Distribution not supported yet');}
  };


  // Checkbox functions
const [checkboxState, setCheckboxState] = useState(false);
const handleCheckboxChange = () => {
  setCheckboxState(prevState => !prevState);
};


// SEARCH BAR LOGIC
useEffect(() => { search(); }, [inputBusca, currentLanguage, currentEpisode, checkboxState]);



// START SEARCH LOGIC
const search = () => {
  if (inputBusca === "" && !checkboxState) {
    setfilteredItems([]); /* Cleans the result lists when search bar is empty */
    setfilteredMobs([]); /* Cleans the result lists when search bar is empty */
    return;
  }

  // Run duplicate ID check asynchronously
  setTimeout(() => {
    const checkDuplicates = (dataArray) => {
      const idCounts = new Map();
      dataArray.forEach((entry) => {
        idCounts.set(entry.ID, (idCounts.get(entry.ID) || 0) + 1);
      });
      return [...idCounts.entries()].filter(([_, count]) => count > 1).map(([id]) => id);
    };
    const duplicateMobIDs = checkDuplicates(currentEpisodeMobDB);
    const duplicateItemIDs = checkDuplicates(currentEpisodeItemDB);
    const allDuplicates = [...duplicateMobIDs, ...duplicateItemIDs];
    if (allDuplicates.length > 0) {
      alert(`Duplicate IDs found: ${allDuplicates.join(", ")}`);
    }
  }, 0);



  if (checkboxState ) {
    setfilteredItems(currentEpisodeItemDB);
    setfilteredMobs(currentEpisodeMobDB);
    return;
  }

  // Updates Filtered Items array with search input
  const filteredItems = currentEpisodeItemDB.filter((item) => {
    const friendlyName =
      (index_items[item.ID] && index_items[item.ID][currentLanguage])
        ? index_items[item.ID][currentLanguage]
        : (index_items[item.ID] && index_items[item.ID].FriendlyName) || item.FriendlyName || item.ShortName || "";

    return (
      friendlyName.toLowerCase().includes(inputBusca.toLowerCase()) ||
      String(item.ID).includes(inputBusca)
    );
  });

  setfilteredItems(filteredItems);

  // Updates Filtered Mob array with search input
  const filteredMobs = currentEpisodeMobDB.filter((mob) => {
    const friendlyName =
      (index_mobs[mob.ID] && index_mobs[mob.ID][currentLanguage])
        ? index_mobs[mob.ID][currentLanguage]
        : (index_mobs[mob.ID] && index_mobs[mob.ID].AegisName) || mob.AegisName || mob.FriendlyName || "";

    return (
      friendlyName.toLowerCase().includes(inputBusca.toLowerCase()) ||
      String(mob.ID).includes(inputBusca)
    );
  });

  setfilteredMobs(filteredMobs);
};

  
 

  // Make Item's 'Dropped by" list clickable
  const makeDroppedByClickable = (mobId) => {
    setselectedItem(null);
    const newSelectedMob = currentEpisodeMobDB.find((mob) => mob.ID == mobId); /* FIX === HERE */
    setselectedMob(newSelectedMob);
  };

  // Make Mob's "drop list" clickable
  const makeDropClickable = (itemId) => {
    setselectedMob(null);
    const newSelectedItem = currentEpisodeItemDB.find((item) => item.ID == itemId); /* FIX === HERE */
    setselectedItem(newSelectedItem);
  };


  const getDroppedBy = (itemId) => {
    const filteredMobs = currentEpisodeMobDB.filter((mob) => {
      const keysToCheck = ['Drop1', 'Drop2', 'Drop3', 'Drop4', 'Drop5', 'Drop6', 'Drop7', 'Drop8', 'Drop9'];
      const hasValue = keysToCheck.some(key => mob[key] === itemId);
      return hasValue;
    })

    const droppedByObj = filteredMobs.map((mob) => {
      return {Rate: mob.rate, mob_id: mob.ID, mob_name: mob.FriendlyName};
    })
  };


  const SpawnMapsTabs = ({ ID }) => {
    const filteredMaps = currentEpisodeMobSpawn.filter((entry) => entry.mob_id == ID); /* FIX === HERE */
    filteredMaps.sort((a, b) => b.quantity - a.quantity);
    const mapIDs = filteredMaps.map((entry) => entry.mapname);
    const allMonstersInMaps = currentEpisodeMobSpawn.filter((entry) => mapIDs.includes(entry.mapname));

    return (
        <div style={{ minHeight: '20px' }}>
            <Tab.Container id="SpawnMapsTable" defaultActiveKey="0">
                <Nav variant="tabs">
                    {filteredMaps.map((entry, index) => (
                        <Nav.Item key={entry.mob_id + entry.mapname}>
                            <Nav.Link eventKey={index}>{entry.mapname} ({entry.quantity})</Nav.Link>
                        </Nav.Item>
                    ))}
                </Nav>
                <Tab.Content>
                    {filteredMaps.map((entry, index) => (
                        <Tab.Pane key={index} eventKey={index}>
                          <div id="cebola" style={{ display: 'flex', alignItems: 'center' }}>
                            <div>
                                <img style={{ maxWidth: '250px' }} src={`https://vitcunha.s3.sa-east-1.amazonaws.com/ragnadb/maps/map_${currentEpisode}/${entry.mapname}.gif`} alt={entry.mapname} />
                            </div>
                            <div style={{ marginLeft: '0px' }}>
                                <ul>
                                    {allMonstersInMaps
                                        .filter((line) => line.mapname == entry.mapname) /* FIX === HERE */
                                        .sort((a, b) => b.quantity - a.quantity) // Sort by MobCount
                                        .map((line2, index2) => (
                                            <li key={index2} style={{ fontWeight: ID == line2.mob_id ? 'bold' : 'normal', color: ID == line2.mob_id ? 'red' : 'inherit' }}>  {/*  */}
                                                { index_mobs[line2.mob_id] ? index_mobs[line2.mob_id][currentLanguage] : line2.mob_id } - {line2.quantity}
                                            </li>
                                        ))}
                                </ul>
                            </div>
                          </div>
                        </Tab.Pane>
                    ))}
                </Tab.Content>
            </Tab.Container>
        </div>
    );
};

  

const SpawnMaps = ({ ID }) => {
  const filteredMaps = currentEpisodeMobSpawn.filter((entry) => entry.mob_id === ID);
  const mapIDs = filteredMaps.map((entry) => entry.mapname);

  const allMonstersInMaps = currentEpisodeMobSpawn.filter((entry) =>
    mapIDs.includes(entry.mapname)
  );

  const spawnList = filteredMaps.map((entry) => (
    <p key={entry.mob_id + entry.mapname}>
      <b>{entry.mapname}</b>
      <ul>
        {allMonstersInMaps
          .filter((line) => line.mapname == entry.mapname)
          .map((line2) => (
            <li>
              {" "}
              {line2.FriendlyName} - {line2.quantity}{" "}
            </li>
          ))}
      </ul>
    </p>
  ));
  return spawnList;
};








{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}
{/* APP RETURN */}
{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}

  return (

/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/
/* WRAPPER DIV */
/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/

<div className={`global-${currentEpisode}`}
style={{minHeight: "100vh",  overflow: "auto", /* Prevent margin collapse */}}>


{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}
{/* TOP + LEFT + RIGHT CONTAINERS */}
{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}


    <div className={`App`}>


{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}
{/* TOP MENU */}
{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}

      <div>{/* TOP MENU */}
        <div >
          <div className={`background-${currentEpisode} top-containers`}>
            <h2 className={`title-${currentEpisode}`} >RagnaDB</h2>

{/* // Base Rate Dropdown Menu */}
            <div className={`title-${currentEpisode}`}>Base:</div>
            <input
              // type="number"
              // step="1"
              min="1"
              style={{ minWidth: "27px", maxWidth: "27px", textAlign: "Center"  }}
              value={baseExpMultiplier}
              onChange={(e) => setbaseExpMultiplier(e.target.value)}
            />

{/* // Job Rate Dropdown Menu */}
            <div className={`title-${currentEpisode}`}>Job:</div>
            <input
              // type="number"
              // step="1"
              min="1"
              style={{ maxWidth: "27px", maxWidth: "27px", textAlign: "Center"  }}
              value={jobExpMultiplier}
              onChange={(e) => setjobExpMultiplier(e.target.value)}
            />

{/* // Drop Rate Dropdown Menu */}
            <div className={`title-${currentEpisode}`}>Drop:</div>
            <input
              //  type="number"
              // step="1"
              min="1"
              style={{ maxWidth: "27px", maxWidth: "27px", textAlign: "Center" }}
              value={dropRateMultiplier}
              onChange={(e) => setdropRateMultiplier(e.target.value)}
            />

{/* // Language Dropdown Menu */}
            <div className={`title-${currentEpisode}`}>Language:</div>
            <select
              value={currentLanguage}
              onChange={(e) => handleChangeLanguage(e.target.value)}
            >
              <option value="en-us">English</option>
              <option value="pt-br">Português</option>
            </select>



{/* // Distribution Dropdown Menu */}         
            <div className={`title-${currentEpisode}`}>Distribution:</div>
            <select
              value={currentDistribution}
              onChange={(e) => setcurrentDistribution(e.target.value)}
            >
              <option value="iRO">iRO</option>
              <option value="kRO">kRO</option>
              <option value="bRO">bRO</option>
              
            </select>

 
{/* // Banner */}               

            <div style={{ width: '100%', textAlign: 'right' }}>
        <img 
          src={`https://vitcunha.s3.sa-east-1.amazonaws.com/ragnadb/headers/header-${currentEpisode}.gif`}
          alt="Header Banner" 
          style={{ width: '90%' }} // Adjust width as needed
        />
      </div>


          </div>
{/* // Grey Bar */}
          <hr />


{/* // SECOND TOP BOX */}
<div className={`background-${currentEpisode} top-containers`}>

{/* // Search Box */}

         <div style={{ display: "flex", gap: "10px", alignItems: 'center', textAlign: 'left'  }}>
         <div style={{  textAlign: 'left', paddingLeft: "20px"  }}>
         <div className={`title-${currentEpisode}`}>Search:</div>
          <input
            placeholder="Item/Monster Name or ID"
            value={inputBusca}
            onChange={(e) => setinputBusca(e.target.value)}
            style={{ display: "flex" }}
          />

<span>Show All </span>
<input
          type="checkbox"
          checked={checkboxState}
          onChange={handleCheckboxChange}
        />
      </div>
          


{/* // Episode Dropdown Menu */}
            <div className={`title-${currentEpisode}`}>Episode:</div>
            <select
              value={currentEpisode}
              onChange={(e) => handleEpisodeChange(e.target.value)}
              style={{maxWidth:"250px"}}
            >
              <option value="default">Default</option>


              {/* Alpha Era */}
              <option value="00">Ep.00 - Alpha Era</option>
              {/* <option value="00000">Ep.00000 - Alpha Era</option> */}

              {/* Beta Era */}
              <option value="0">Ep.0 - Beta Era</option>
              {/* <option value="0000">Ep.0000 - MBT 1</option>
              <option value="000">Ep.000 - MBT 1, Part 2</option>
              <option value="00">Ep.00 - CBT 2</option> 
              <option value="0">Ep.0 - OBT 2</option> */}

              {/* Launch Era */}
              <option value="1">Ep.1 - Start of an Adventure</option>

              {/* Clock Tower + GH Dun */}
              <option value="1.5">Ep.1.5 - Attack of the Ancient (Clock Tower + GH dun)</option>
              {/* <option value="2">Ep.2 - Lutie</option>
              <option value="3">Ep.3 - Comodo</option> */}


              {/* Comodo + Lutie Era */}
              <option value="2">Ep.2 - Lutie</option>
              {/* <option value="2">Ep.2 - Lutie</option>
              <option value="3">Ep.3 - Comodo</option> */}

              {/* Comodo */}
              <option value="3">Ep.3 - Comodo</option>
              {/* <option value="2">Ep.2 - Lutie</option>
              <option value="3">Ep.3 - Comodo</option> */}

              {/* Turtle Island + Woe Era */}
              <option value="4">Ep.4 - Turtle Island + WoE</option>

              {/* Juno Era */}
              <option value="5">Ep.5 - Juno</option>

              {/* Old Times Era */}
              <option value="7">Ep.6~7 - Old Times Era</option>
              {/* <option value="6">Ep.6 - Amatsu</option>
              <option value="6.2">Ep.6.2 - Kunlun</option>
              <option value="7">Ep.7 - Umbala</option>
              <option value="7.1">Ep.7.1 - Nifflheim</option> */}

              {/* Heritage Era */}
              <option value="10">Ep.8~10 - Heritage Era</option>
              {/* <option value="8.1">Ep.8.1 - Louyang</option>
              <option value="8.2">Ep.8.2 - Ayothaya</option>
              <option value="10">Ep.10 - Rebirth</option>
              <option value="10.05">Ep.10.05 - Jawaii</option> */}

              {/* Gold Times Era */}
              <option value="11">Ep.10~11 - Gold Times Era</option>
              {/* <option value="10.1">Ep.10.1 - Einbroch</option>
              <option value="10.2">Ep.10.2 - Lighthalzen</option>
              <option value="10.25">Ep.10.25 - Homunculus</option>
              <option value="10.3">Ep.10.3 - Noghaltz</option>
              <option value="10.4">Ep.10.4 - Hugel</option>
              <option value="11.1">Ep.11.1 - Rachel</option>
              <option value="11.2">Ep.11.2 - Veins</option>
              <option value="11.3">Ep.11.3 - Nameless Island</option> */}

              {/* Satan Morroc Era */}
              <option value="12">Ep.12~13 - Satan Morroc Era</option>
              {/* <option value="12">Ep.12 - Satan Morroc</option>
              <option value="12.1">Ep.12.1 - WOE : SE</option>
              <option value="12.2">Ep.12.2 - Moscovia</option>
              <option value="12.3">Ep.12.3 - Battlegrounds</option>
              <option value="12.4">Ep.12.4 - Endless Tower</option>
              <option value="12.5">Ep.12.5 - Sealed Shrine</option>
              <option value="13.1">Ep.13.1 - Ash Vacuum</option>
              <option value="13.11">Ep.13.11 - Poring Wars</option> */}
              

              {/* Renewal */}
              <option value="13">Ep.13+ - Renewal</option>
              {/* <option value="13.15">Ep.13.15 - Renewal</option> */}

            </select>

            </div>





</div>
{/* // Grey Bar */}
          <hr />


{/* // Whole Container */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              gap: "25px",
            }}
          >

{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}
{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}            
{/* // LEFT CONTAINER */}
{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}
{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}

            <div>

{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}            
{/* // ITEM RESULTS LIST */}
{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}

<div className={`title-${currentEpisode}`}>Items</div>
<br />
<select className="results-list"
  style={{ textAlign: "left", width: "200px", outline: "none" }}
  size="13"
  onChange={(event) => {
    const selectedID = event.target.value;
    const item = filteredItems.find(item => item.ID === selectedID);
    if (item) {
      setselectedMob(null);
      setselectedEpisodeDetails(null);
      // Force reloading the same item by setting state to null before updating it
      setselectedItem(null); // Optional: reset selected item first
      setTimeout(() => {
        setselectedItem(item);
      }, 0);
    }
  }}
>
  {filteredItems.map((item) => (
    <option key={item.ID} value={item.ID}>
      {item.ID} - { index_items[item.ID] ? index_items[item.ID][currentLanguage] : ("* - " + item.FriendlyName) } {item.Slots && item.Slots != 0 && `[${item.Slots}]`}
    </option>
  ))}
</select>


{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}            
{/* // MOB RESULTS LIST */}
{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}

<div className={`title-${currentEpisode}`}>Monsters</div>
<br />
<select className="results-list"
  style={{ textAlign: "left", width: "200px", outline: "none" }}
  size="13"
  onChange={(event) => {
    const selectedID = event.target.value;
    const mob = filteredMobs.find(mob => mob.ID === selectedID);
    if (mob) {
      setselectedItem(null);
      setselectedEpisodeDetails(null);
      // Force reloading the same mob by setting state to null before updating it
      setselectedMob(null); // Optional: reset selected mob first
      setTimeout(() => {
        setselectedMob(mob);
      }, 0);
    }
  }}
>
  {filteredMobs.map((mob) => (
    <option key={mob.ID} value={mob.ID}>
      {mob.ID} - { index_mobs[mob.ID][currentLanguage] ? index_mobs[mob.ID][currentLanguage] : ("* - " + mob.AegisName) }
    </option>
  ))}
</select>

<br /> <br />

{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}     
{/* // EPISODE DETAILS BUTTON */}
{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}



<div>
      <button style={{ display: "flex", width: "100%", justifyContent: "center", alignItems: "center", textAlign: "center", color: "inherit", backgroundColor: "inherit" }}

      onClick={() => {
        setselectedItem(null);
        setselectedMob(null);
        setselectedEpisodeDetails(currentEpisode);
       {/* alert('Button was clicked!'); */}
      }}>
        <p style={{ cursor: 'pointer', padding: '10px', margin: 0, }}>Episode Details</p>
      </button>
</div>




{/* // End Left Container*/}
            </div>
{/* // Start Right Container*/}



{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}
{/* // RIGHT CONTAINER */}
{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}
            <div 
            className={`background-${currentEpisode}`}
              style={{
                display: "flex",
                width: "100%",
               /*  border: "1px solid grey", */
                textAlign: "Left",
                padding: "12px",
              }}
            >

{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}
{/* // ITEM DETAILS */}
{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}

              {selectedItem !== null && (
                <div>
                <ul style={{ listStyle: "none", padding: "0" }}>



  <li>Icon<img src={`https://vitcunha.s3.sa-east-1.amazonaws.com/ragnadb/icons/${selectedItem.ID}.png`}></img></li>
  <li>Image<img src={`https://vitcunha.s3.sa-east-1.amazonaws.com/ragnadb/images/${selectedItem.ID}.png`}></img>{" "}</li>
  <li>Image (Old)<img src={`https://vitcunha.s3.sa-east-1.amazonaws.com/ragnadb/images-old/${selectedItem.ID}.png`}></img></li>
  <li><b>{generalindex[currentLanguage].general.name|| "Name"}:</b> { index_items[selectedItem.ID] ? index_items[selectedItem.ID][currentLanguage] : (selectedItem.FriendlyName) } |{" "}{selectedItem.FriendlyName}</li>
  <li><b>ID:</b> {selectedItem.ID}</li>
  <li><b>{generalindex[currentLanguage].general.npc_price|| "Buy At"}: </b>{selectedItem.NPCPrice}z</li>
  <li><b>{generalindex[currentLanguage].general.sell_at|| "Sell At"}: </b> {selectedItem.NPCPrice / 2}z</li>
  {selectedItem.ATK > 0 && (<li><b>ATK: </b>{selectedItem.ATK}</li>)}
  {selectedItem.MATK > 0 && (<li><b>MATK: </b>{selectedItem.MATK}</li>)}
  {selectedItem.DEF > 0 && (<li><b>DEF: </b>{selectedItem.DEF}</li>)}
  {selectedItem.MDEF > 0 && (<li><b>MDEF: </b>{selectedItem.MDEF}</li>)}
  {selectedItem.Slots > 0 && (<li><b>Slots: </b>{selectedItem.Slots}</li>)}
  {selectedItem.MinLvl > 0 && (<li><b>{generalindex[currentLanguage].general.level_needed|| "Minimum Level"}: </b>{selectedItem.MinLvl}</li>)}
  {selectedItem.STR > 0 && <li> STR+{selectedItem.STR}</li>}
  {selectedItem.AGI > 0 && <li> AGI+{selectedItem.AGI}</li>}
  {selectedItem.VIT > 0 && <li> VIT+{selectedItem.VIT}</li>}
  {selectedItem.INT > 0 && <li> INT+{selectedItem.INT}</li>}
  {selectedItem.DEX > 0 && <li> DEX+{selectedItem.DEX}</li>}
  {selectedItem.LUK > 0 && <li> LUK+{selectedItem.LUK}</li>}
  {selectedItem.CardHit > 0 && (<li> CardHit+{selectedItem.CardHit}</li>)}
  {selectedItem.CardCrit > 0 && (<li> CardCrit+{selectedItem.CardCrit}</li>)}
  {selectedItem.CardFlee > 0 && (<li> CardFlee+{selectedItem.CardFlee}</li>)}
  {selectedItem.CardSkill > 0 && (<li> CardSkill+{selectedItem.CardSkill}</li>)}
  {currentEpisodeItemDescription[selectedItem.ID] && (
  <>
  {currentEpisodeItemDescription[selectedItem.ID].description && (<li><b>{generalindex[currentLanguage].general.description}: </b>{currentEpisodeItemDescription[selectedItem.ID].description}</li>)}
  {currentEpisodeItemDescription[selectedItem.ID].weight && (<li><b>{generalindex[currentLanguage].general.weight}: </b>{currentEpisodeItemDescription[selectedItem.ID].weight}</li>)}
  {currentEpisodeItemDescription[selectedItem.ID].item_class && (<li><b>{generalindex[currentLanguage].general.item_class}: </b>{currentEpisodeItemDescription[selectedItem.ID].item_class}</li>)}
  {currentEpisodeItemDescription[selectedItem.ID].defense && (<li><b>{generalindex[currentLanguage].general.defense}: </b>{currentEpisodeItemDescription[selectedItem.ID].defense}</li>)}
  {currentEpisodeItemDescription[selectedItem.ID].weapon_attack && (<li><b>{generalindex[currentLanguage].general.weapon_attack}: </b>{currentEpisodeItemDescription[selectedItem.ID].weapon_attack}</li>)}
  {currentEpisodeItemDescription[selectedItem.ID].weapon_level && (<li><b>{generalindex[currentLanguage].general.weapon_level}: </b>{currentEpisodeItemDescription[selectedItem.ID].weapon_level}</li>)}
  {currentEpisodeItemDescription[selectedItem.ID].level_required && (<li><b>{generalindex[currentLanguage].general.level_required}: </b>{currentEpisodeItemDescription[selectedItem.ID].level_required}</li>)}
  {currentEpisodeItemDescription[selectedItem.ID].applicable_jobs && (<li><b>{generalindex[currentLanguage].general.applicable_jobs}: </b>{currentEpisodeItemDescription[selectedItem.ID].applicable_jobs}</li>)}
  {currentEpisodeItemDescription[selectedItem.ID].element && (<li><b>{generalindex[currentLanguage].general.element}: </b>{currentEpisodeItemDescription[selectedItem.ID].element}</li>)}
  {currentEpisodeItemDescription[selectedItem.ID].equipped_on && (<li><b>{generalindex[currentLanguage].general.equipped_on}: </b>{currentEpisodeItemDescription[selectedItem.ID].equipped_on}</li>)}
  </>)}

  
                  </ul>


{/* ITEM - CARD IMAGE */}

{selectedItem && (
  <img
    src={
      `https://vitcunha.s3.sa-east-1.amazonaws.com/ragnadb/cards${currentEpisode === "0" || currentEpisode === "000" ? "-old" : ""}/${selectedItem.ID}.png`
    }
    alt={`Card ${selectedItem.ID}`}
    onError={(e) => e.target.style.display = "none"} // Hide the image if it fails to load
    onLoad={(e) => e.target.style.display = "inline"} // Show the image if it loads successfully
  />
)}

                    <br></br><br></br>




{/* // ITEM - DROPPED BY */}

                        <b>Dropped By:</b>

                        {currentEpisodeDroppedBy[selectedItem.ID] && (
                          <ul>
                            {currentEpisodeDroppedBy[selectedItem.ID].map(droppedByEntry => 
                                <li className={`title-${currentEpisode} link`} onClick={() => makeDroppedByClickable(droppedByEntry.mob_id)}>
                                  <img src={`https://vitcunha.s3.sa-east-1.amazonaws.com/ragnadb/gifs/${droppedByEntry.mob_id}.gif`} style={{maxWidth: '30px', maxHeight: '30px'}} ></img>
                                  { index_mobs[droppedByEntry.mob_id] ? index_mobs[droppedByEntry.mob_id][currentLanguage] : droppedByEntry.mob_id }
                                  { } - { }
                                  {(droppedByEntry.rate / 100 * dropRateMultiplier) > 100 ? 100 : (droppedByEntry.rate / 100 * dropRateMultiplier)}%

                                </li>
                              )} 
                          </ul>
                        ) || "No monsters drop this item." }



{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}
</div>
)}
{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}
{/* // MOB DETAILS */}
{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}
{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}

              {selectedMob !== null && (
                <div>
                  <ul style={{ listStyle: "none", padding: "0" }}>
                    <li>Sprite (Gifs) <img src={`https://vitcunha.s3.sa-east-1.amazonaws.com/ragnadb/gifs/${selectedMob.ID}.gif`} alt="" /></li>
                    <br />
                    <li><b>{generalindex[currentLanguage].general.name|| "Name"}:</b> { index_mobs[selectedMob.ID] ? index_mobs[selectedMob.ID][currentLanguage] : selectedMob.FriendlyName } | {selectedMob.FriendlyName}</li>
                    <li><b> ID:</b> {selectedMob.ID}</li>
                    <li><b> Level:</b> {selectedMob.LV}</li>
                    <li><b> HP:</b> {selectedMob.HP}</li>
                    <li><b> {generalindex[currentLanguage].general.base_exp || "Base Exp"}:</b> {selectedMob.EXP * baseExpMultiplier}</li>
                    <li><b> {generalindex[currentLanguage].general.job_exp || "Job Exp"}:</b> {selectedMob.JEXP * jobExpMultiplier}</li>
                    <li><b> {generalindex[currentLanguage].general.size || "Size"}: </b>{generalindex[currentLanguage].size[selectedMob.Size]}</li>
                    <li><b> {generalindex[currentLanguage].general.race || "Race"}:</b> {generalindex[currentLanguage].race[selectedMob.Race]}</li>
                    <li><b> {generalindex[currentLanguage].general.element || "Property"}:</b> {generalindex[currentLanguage].element[selectedMob.Element.charAt(1)]} {selectedMob.Element.charAt(0)/2}</li>
                    <br />
                    <li><b> MinAtk:</b> {selectedMob.MinAtk}</li>
                    <li><b> MaxAtk:</b> {selectedMob.MaxAtk}</li>
                    <li><b> DEF:</b> {selectedMob.DEF}</li>
                    <br />
                    <li><b> STR:</b> {selectedMob.STR}</li>
                    <li><b> AGI:</b> {selectedMob.AGI}</li>
                    <li><b> VIT:</b> {selectedMob.VIT}</li>
                    <li><b> INT:</b> {selectedMob.INT}</li>
                    <li><b> DEX:</b> {selectedMob.DEX}</li>
                    <li><b> LUK:</b> {selectedMob.LUK}</li>

                    <br /> 


{/* // DROP LIST / Droptable */}
                   <div>
                    {DROP_SLOTS_ARRAY.map(
                      (dropSlot) =>
                        selectedMob[`Drop${dropSlot}`] > 0 && (
                          <DropEntry 
                            dropID={selectedMob[`Drop${dropSlot}`]}
                            dropRateMultiplier={dropRateMultiplier}
                            baseDropRate={selectedMob[`Rate${dropSlot}`]}
                            makeDropClickable={makeDropClickable}
                            itemIndex={index_items}
                            currentEpisode={currentEpisode}
                            currentLanguage={currentLanguage}
                          />
                        )
                    )}
                    </div>
                  </ul>
                  <br />


{/* // MAP TABS */}
                    {/* New Spawn List (Tabs) */}  
                    <SpawnMapsTabs ID={selectedMob.ID}/>
                    {/* Old Spawn List */}  
                    {/* <SpawnMaps ID={selectedMob.ID}/> */}


<br></br><br></br>
                </div>
              )} 
{/* // MOB DETAIL ENDS */}


{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}
{/* // EPISODE DETAILS */}
{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}


{/* 
{selectedEpisodeDetails !== null && 
              <div>
                    {selectedEpisodeDetails === "00" ? (
                      <p>alpha</p>
                    ) : selectedEpisodeDetails === "default" ? (
                      <p>default</p>
                    ) : (
                      <p>No matching episode</p>
                    )}
              </div>
}
*/}

<EpisodeDescriptionsBlock selectedEpisodeDetails={selectedEpisodeDetails}/>

{/* // EPISODE DETAIL ENDS */}

            </div>
          </div>
        </div>
      </div>
    </div>  {/* // TOP + LEFT + RIGHT CONTAINER END */}


{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}
{/* // FOOTER */}
{/*%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%*/}


{/* 
{    <div style={{
      padding: '10px',
      backgroundColor: '#f1f1f1',
      color: '#333',
      fontSize: '13px',
      fontFamily: 'Arial, sans-serif',
      textAlign: 'left'
    }}>
      made with <span style={{ color: 'red' }}>♥</span> by vitcunha and oldinroplayer <br></br>
   
      special thanks to mangusto, Vara, Bunny, Fenix, Kienna
    </div>
} */}



    </div>  /* WRAPPER DIV END */
  
  ); {/* // APP RETURN ENDS */}

} {/* // APP FUNCTION */}
